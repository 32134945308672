<template>
    <div class="doubleManage">
        <headLine msg='双选会管理'></headLine>   
        <div class="topBtn">
            <el-button type="primary" size="small" 
            @click="add"
            icon="iconfont icon-xinzeng"
            >
                新增双选会
            </el-button>
            <span>按条件查询：</span>
            <el-select
              v-model="state"
              placeholder="请选择" 
              clearable
              size="medium"
              @change="choseStatus"
             >
                <el-option
                v-for="item in statusList"
                :key="item.name"
                :label="item.name"
                :value="item.status">
                </el-option>
            </el-select>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
            <el-table-column 
            label="状态"
            width="80">
                <template slot-scope="scope">  
                    <el-tag
                    :type="scope.row.state==1?'warning':scope.row.state==2?'primary':
                    scope.row.state==3?'success':'info'"
                    disable-transitions>
                    
                    {{scope.row.state==1?'未公开':scope.row.state==2?'已公开':
                    scope.row.state==3?'已公布':'已结束'}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
             :show-overflow-tooltip="true"
             width="180"
            label="双选会时间">
                <template slot-scope="scope">
                    <span>{{scope.row.startTime}}~{{scope.row.endTime}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            prop="dcName"
             width="160"
            label="双选会名称">
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            prop="sponsor"
            label="主办方名称">
            </el-table-column>
             <el-table-column
            :show-overflow-tooltip="true"
            prop="secSponsor"
            label="从办方名称">
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="学校区域">
                <template slot-scope="scope">
                    <span v-for="(v,i) in scope.row.orgAreas" :key="i">
                        {{v.name}} 
                    </span> 
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
             
            label="学生规模">
               <template slot-scope="scope">
                    <span class="fontweight">
                       {{scope.row.orgIds?getorgNum(scope.row.orgIds):false}}个校区,
                       {{scope.row.stuNums}}个学生
                    </span> 
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            prop="createName"
            label="发布人">
            </el-table-column>
            <el-table-column label="状态操作">
                <template slot-scope="scope">
                    <el-button
                    size="mini" 
                    :disabled="scope.row.state==4"
                    :type="scope.row.state==1?'warning':scope.row.state==2?'primary':
                    scope.row.state==3?'success':'info'"
                    @click="changeStatus(scope.row.id,scope.row.state)">
                    {{scope.row.state==1?'是否公开':scope.row.state==2?'是否公布':
                    scope.row.state==3?'是否结束':'已结束'}}
                    </el-button>
                  
                </template>
                 
            </el-table-column>
            <el-table-column label="操作" width="140">
                <template slot-scope="scope">
                    <el-button
                    type="primary"
                    size="mini"
                    style="margin-right:6px"
                    @click="editor(scope.row)"
                    :disabled="scope.row.state==4"
                    >编辑</el-button>
                    
                    <el-popconfirm
                    title="确定删除该双选会吗？"
                    @onConfirm='delPosition(scope.row.id)'
                    >
                    <el-button slot="reference"
                    :disabled="scope.row.state==3" size="mini" type="danger">删除</el-button>
                    </el-popconfirm>
                    
                </template>
            </el-table-column>
        </el-table>
        <paging v-show="tableData.length>0" :totalpage="totalpage" @getInitList="getInitList" ref="pages" v-on:handle="handle"></paging>
        
    </div>
</template>
<script>
// 1 未公开warning  2 已公开danger 3 已发布success  4 已结束info

import {statusList} from '../../service/config'
export default {
    data() {
        return {
            totalpage:null,
            statusList,
            state:0,
            tableData:[],
            teaId:'',
            current:0,
            status:'',
            tipList:[
                {state:1,text:'向企业开放双选会申请通道，请检查好双选会相关内容',url:"/tea/dc/open"},
                {state:2,text:'向相关学校学生开放双选会通道，请确认无误后进行操作，一旦公开将无法删除，只能结束',
                url:"/tea/dc/public"},
                {state:3,text:'结束双选会',url:'/tea/dc/end'},
                {state:4}
            ]
        }
    },
    methods: { 
         getorgNum(orgIds){
            return orgIds.split(',').length
        },
        //编辑
        editor(v){
            this.$router.push({name:'dbmodel',params:{v,titleCode:1}})
        },
        changeStatus(id,state){
            let tip,url;
            this.tipList.forEach(e=>{
                if(e.state==state){
                    tip=e.text
                    url=e.url
                }
            })
            this.$confirm(`此操作将${tip}, 是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$htp.get_(url+'?dcId='+id,true).then(()=>{
                    this.getInitList(this.current,this.state)     
                })
            }).catch(() => {});   
        },   
        handle(val){
            this.current=val  
        }, 
        choseStatus(val){
            this.status=val
            this.getInitList(0)
        },
        delPosition(id){
            this.$htp.del_('/tea/dc/delete?dcId='+id,{},true).then(()=>{
                 this.getInitList(this.current,this.state)     
            }) 
        },
        add(){
        //     if(this.$refs.transfer) {
        //         this.$refs.transfer.initClass()
        //    } 
          this.$router.push({name:'dbmodel',params:{titleCode:0}})
        },   
        getInitList(current=0){
            let url;
            
            if(this.status){
                url='/tea/dc/list?page='+current+'&teaId='+this.teaId+'&status='+this.status
            }else{
                url='/tea/dc/list?page='+current+'&teaId='+this.teaId
            }        
            this.$htp.get_(url).then((res)=>{
                this.tableData=res.data.rows;
                this.totalpage=res.data.total;
            }) 
        },    
    },
    mounted(){
        this.teaId=sessionStorage.getItem('id')
        this.getInitList(0)    
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/doubleElection.scss';
</style>
<style>
.el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label{
    cursor: pointer;
}
</style>